import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import * as Styles from '../styles/pages/_member.module.scss';

import split from '../images/split.svg';
import arrowDown from '../images/arrowDown.svg';

import Layout from '../components/layout';
import Seo from '../components/seo';
import SectionTitle from '../components/section-title';
import MemberCard from '../components/member-card';

const Member = ({ location, pageContext }) => {
  const {
    nodes,
  } = pageContext;

  const [tabItem, setTabItem] = useState('経営');
  const [isMobile, setIsMobile] = useState(false);

  const resizeEvent = useCallback(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1200) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setIsMobile(true);
    }
    resizeEvent();
    return () => window.removeEventListener('resize', resizeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterMemberList = useCallback(() => nodes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ?.filter((node) => node.department[0] === tabItem), [tabItem]);

  const [memberList, setMemberList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const onClickOption = (title) => {
    setTabItem(title);
    setIsOpen(false);
  };

  useEffect(() => {
    setMemberList(filterMemberList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const list = filterMemberList();
    setMemberList(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabItem]);

  // TODO: 別の場所で定義する
  const tabTitleList = [
    '経営',
    'エンジニアリング',
    'プロダクト',
    'デザイン',
    'セールス',
    'カスタマーサクセス',
    'マーケティング',
    'コーポレート',
    'HR',
  ];

  const filterSelectTitleList = useCallback(() => tabTitleList
    .filter((tab) => nodes?.filter((node) => node.department[0] === tab).length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    .filter((title) => title !== tabItem), [nodes, tabItem]);

  const filterTabTitleList = useCallback(
    () => tabTitleList
      .filter((tab) => nodes?.filter((node) => node.department[0] === tab).length > 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes],
  );

  const isVisibleSVG = (tabTitleIndex) => filterTabTitleList().length - 1 !== tabTitleIndex;

  return (
    <>
      <Seo title="メンバー一覧" url={location.origin} />
      <Layout location={location.pathname}>
        <section>
          <div className={Styles.member_page__header}>
            <SectionTitle>member</SectionTitle>
            <h1 className={Styles.member_page__title}>メンバー一覧</h1>
          </div>

          {isMobile
            ? (
              <div className={Styles.member_page__custom_select_wrapper}>
                <div className={Styles.member_page__select_wrapper}>
                  <div
                    className={Styles.member_page__custom_select}
                    role="button"
                    tabIndex="0"
                    onClick={() => setIsOpen(!isOpen)}
                    onKeyDown={() => setIsOpen(!isOpen)}
                  >
                    <span>{tabItem}</span>
                    <img src={arrowDown} alt="arrowDownIcon" />
                  </div>
                  <div className={isOpen
                    ? Styles.member_page__custom_select__option__active
                    : Styles.member_page__custom_select__option}
                  >
                    {filterSelectTitleList().map((title) => (
                      <div
                        role="button"
                        tabIndex="0"
                        className={title === tabItem
                          ? Styles.member_page__custom_select__option__item__active
                          : Styles.member_page__custom_select__option__item}
                        key={title}
                        value={title}
                        onClick={() => onClickOption(title)}
                        onKeyDown={() => onClickOption(title)}
                      >
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className={Styles.member_page__tab}>
                {filterTabTitleList()?.map((title, i) => (
                  <div className={Styles.member_page__tab__item} key={title}>
                    <button
                      type="button"
                      className={tabItem === title
                        ? Styles.member_page__tab__item__active : undefined}
                      onClick={() => setTabItem(title)}
                    >
                      {title}
                    </button>
                    {isVisibleSVG(i) && (
                      <img src={split} alt="split" />
                    )}
                  </div>
                ))}
              </div>
            )}

          <div className={Styles.member_page__content}>
            <h2 className={Styles.member_page__card_title}>{tabItem}</h2>
            <div className={Styles.member_page__container}>
              <ul className={classNames('row', Styles.member_page__list)}>
                {memberList?.map((member) => (
                  <MemberCard key={member.memberId} item={member} />
                ))}
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Member;
