import React from 'react';
import classNames from 'classnames';
import * as Styles from '../styles/_section_title.module.scss';

const SectionTitle = ({ left, white, children }) => (
  <p className={classNames(Styles.title, left && Styles.left, 'mashine')}>
    <span className={classNames(Styles.title__text, white && Styles.white)}>
      {children}
    </span>
  </p>
);

export default SectionTitle;
